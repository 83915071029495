import React from 'react';
import cx from 'classnames';
import CurveDown from '../../images/layout/curve-down.svg';
import styles from './styles/BlueSection.module.scss';

const BlueSection = ({
  className,
  curveClassName,
  wrapClassName,
  children
}) => {
  return (
    <div className={cx(styles.container, className)}>
      <div className={cx('container', wrapClassName)}>
        {children}
      </div>
      <CurveDown className={cx(styles.curve, curveClassName)} />
    </div>
  );
};

export default BlueSection;
