import React from 'react';
import cx from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import styles from './styles/quashed-plus.module.scss';
import PageHeader from '../components/sections/PageHeader';
import Sponsors from '../components/Sponsors';
import BlueSection from '../components/sections/BlueSection';
import { Scroll } from '../components/Animation';
import { INSURANCE_TYPES_NAMES, INSURANCE_TYPE_CAR } from '../constants';
import { INSURANCE_TYPES_ICONS } from '../constants/insurance';
import LinkButton from '../components/LinkButton';
import Lottie from '../components/Lottie';
import { GET_STARTED } from '../constants/routes';

const averageMarketScanSavings = [
  {
    type: INSURANCE_TYPES_NAMES.VEHICLE,
    label: INSURANCE_TYPE_CAR.VEHICLE,
    amount: '247',
  },
  {
    type: INSURANCE_TYPES_NAMES.HOME,
    amount: '415',
  },
  {
    type: INSURANCE_TYPES_NAMES.CONTENT,
    amount: '244',
  },
];

const SponsorSection = () => {
  return (
    <div className={styles.sponsorsContainer}>
      <Sponsors
        className={styles.sponsors}
        titleClassName={styles.sponsorTitle}
      />
    </div>
  );
};

// Average kiwi saves
const Section1 = ({ newZealand }) => {
  return (
    <BlueSection
      className={styles.sectionBlue}
      wrapClassName={styles.sectionBlueWrap}
      curveClassName={styles.hide}
    >
      <div className={styles.left}>
        <h1>In 2023, Quashed Market Scan saved Kiwis on average</h1>
        <div className={styles.savings}>
          {averageMarketScanSavings.map((saving) => {
            const Icon = INSURANCE_TYPES_ICONS[saving.type];
            return (
              <div key={saving.type} className={styles.saving}>
                <div className={styles.verticalLine}></div>
                <Icon className={styles.savingIcon} />
                <div className={styles.savingText}>
                  <h1>${saving.amount}</h1>
                  <p>on {saving.label || saving.type} insurance</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <Image
        className={styles.newZealand}
        fluid={newZealand.childImageSharp.fluid}
      />
    </BlueSection>
  );
};

// How much does it cost
const Section2 = ({ images }) => {
  return (
    <div className={styles.sectionGrey}>
      <div className={cx('container', styles.costContainerWrap)}>
        <div className={styles.top}>
          <Image
            className={styles.mobileMS}
            fluid={images.mobileMS.childImageSharp.fluid}
          />

          <div className={styles.costTextContainer}>
            <h1>Check to see if you have the best deal and coverage</h1>
            <div className={styles.blueBarSection}>
              <div className={styles.blueBar}></div>
              <div className={styles.costText}>
                <h2>On average Kiwis can save</h2>
                <h1>$1,150+</h1>
                <p>across cars, house and contents insurance & more</p>
              </div>
            </div>
            <LinkButton
              href={GET_STARTED}
              background="#4187F5"
              className={styles.blueBtn}
            >
              Find me better deals
            </LinkButton>
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.left}>
            <div className={styles.whiteBarContainer}>
              <h1>Shop smarter with Quashed</h1>
              <p>
                Insurance just got way easier with Quashed. Compare, shop and
                track all your insurance in one place.
              </p>
            </div>

            <LinkButton
              href={GET_STARTED}
              background="#54A702"
              className={styles.greenBtn}
            >
              Get started today
            </LinkButton>
          </div>
          <div className={styles.savingsImgContainer}>
            <Lottie className={styles.savingsImg} name="savings" />
          </div>
        </div>
        <div className={styles.disclaimer}>
          We scan through 10+ insurance companies, though please note that this
          may not cover the entire market, as some insurers don't offer online
          quotes. Additionally, if you reside in high-risk areas like
          Christchurch, Wellington, etc., the options for house and contents
          insurance might be limited. Kindly note that we do not offer advice;
          our service solely provides online comparisons to aid you in finding
          insurance.
        </div>
      </div>
    </div>
  );
};

// Testimonials
const Section3 = ({ images }) => {
  const testimonials = [
    {
      name: 'Jess W',
      image: images.jessProfile.childImageSharp.fluid,
      city: 'Wellington',
      savedTotal: '353',
      savings: [
        { amount: '189', type: INSURANCE_TYPES_NAMES.CONTENT },
        {
          amount: '164',
          type: INSURANCE_TYPES_NAMES.VEHICLE,
          label: INSURANCE_TYPE_CAR.VEHICLE,
        },
      ],
    },
    {
      name: 'Sheldon B',
      image: images.sheldonProfile.childImageSharp.fluid,
      city: 'Christchurch',
      savedTotal: '1,034',
      savings: [
        { amount: '292', type: INSURANCE_TYPES_NAMES.CONTENT },
        {
          amount: '156',
          type: INSURANCE_TYPES_NAMES.VEHICLE,
          label: INSURANCE_TYPE_CAR.VEHICLE,
        },
        {
          amount: '586',
          type: INSURANCE_TYPES_NAMES.VEHICLE,
          label: INSURANCE_TYPE_CAR.VEHICLE,
        },
      ],
    },
    {
      name: 'John F',
      image: images.johnProfile.childImageSharp.fluid,
      city: 'Auckland',
      savedTotal: '2,466',
      savings: [
        { amount: '64', type: INSURANCE_TYPES_NAMES.HOME },
        { amount: '220', type: INSURANCE_TYPES_NAMES.CONTENT },
        {
          amount: '1,374',
          type: INSURANCE_TYPES_NAMES.VEHICLE,
          label: INSURANCE_TYPE_CAR.VEHICLE,
        },
        {
          amount: '808',
          type: INSURANCE_TYPES_NAMES.VEHICLE,
          label: INSURANCE_TYPE_CAR.VEHICLE,
        },
      ],
    },
  ];
  return (
    <div className={styles.sectionWhite}>
      <div className={cx(styles.sectionWhiteWrap, 'container')}>
        <h1>How much can I potentially save?</h1>
        <h2>
          Check out the savings achieved by some of our members through Market
          Scan
        </h2>
        <div className={styles.testimonialContainer}>
          {testimonials.map((testimonial) => {
            return (
              <div key={testimonial.name} className={styles.testimonial}>
                <Image
                  className={styles.profileImage}
                  fluid={testimonial.image}
                />
                <div className={styles.text}>
                  <h2>{testimonial.name}</h2>
                  <h3>from {testimonial.city} saved</h3>
                  <h1>${testimonial.savedTotal}</h1>
                  {testimonial.savings.map((saving) => {
                    const Icon = INSURANCE_TYPES_ICONS[saving.type];
                    return (
                      <div key={saving.amount} className={styles.saving}>
                        <Icon className={styles.savingIcon} />
                        <h3>
                          ${saving.amount} on{' '}
                          {(saving.label || saving.type).toLowerCase()}
                        </h3>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
        <LinkButton
          href={GET_STARTED}
          background="#4187F5"
          className={styles.blueBtn}
        >
          Find my savings
        </LinkButton>
      </div>
    </div>
  );
};

// Insurance rises
const Section4 = ({ images }) => {
  return (
    <div className={styles.sectionGrey}>
      <div className={cx('container', styles.priceRiseWrap)}>
        <div className={styles.left}>
          <h1>Act now to beat the insurance price rise</h1>
          <p>
            Comparing insurance providers has never been more crucial. With
            insurance companies raising prices by 20-30% annually, you could see
            your insurance costs increase by $886 to $1,329 this year!
          </p>
          <LinkButton
            href={GET_STARTED}
            background="#4187F5"
            className={styles.blueBtn}
          >
            Sign me up now
          </LinkButton>
        </div>
        <div className={styles.right}>
          <Image
            className={styles.mobileMS}
            fluid={images.priceIncreaseGraph.childImageSharp.fluid}
          />
        </div>
      </div>
    </div>
  );
};

// Quashed benefits
const Section5 = () => {
  const bulletPoints = [
    'Instantly save big on car, home, and contents insurance',
    "Compare your current policy's premiums and features with other market options",
    'Store all your policies and we’ll help you shop for insurance every year on your behalf',
    'Share your Quashed dashboard with loved ones for emergency access',
    'Unlock our credit card benefit to explore insurance perks included with your cards',
  ];
  return (
    <div className={styles.sectionWhite}>
      <div className={cx(styles.sectionWhiteWrap, 'container')}>
        <h1>Get all the benefits of Quashed when you buy Market Scan</h1>
        <div className={styles.bulletPoints}>
          {bulletPoints.map((point, idx) => {
            return (
              <div key={idx} className={styles.bulletPoint}>
                <i className="fa-regular fa-circle-check" />
                <p>{point}</p>
              </div>
            );
          })}
        </div>
        <LinkButton
          href={GET_STARTED}
          background="#54A702"
          className={styles.blueBtn}
        >
          Get started today
        </LinkButton>
      </div>
    </div>
  );
};

// No risk
const Section6 = () => {
  return (
    <div className={styles.sectionGrey}>
      <div className={cx(styles.noRiskWrap, 'container')}>
        <div className={styles.left}>
          <h1>No risks, only savings!</h1>
          <p>
            We’re here to help you save time and money when it comes to
            insurance. One place to compare, shop and manage all your insurance.
          </p>
        </div>
        <div className={styles.right}>
          <Lottie className={styles.noRiskImg} name="piggy" />
        </div>
      </div>
    </div>
  );
};

const Home = () => {
  const meta = [
    { name: 'geoRegion', content: 'NZ-AUK' },
    { name: 'geoPlacename', content: 'Auckland' },
    { name: 'geoPosition', content: '-36.8114642;174.6210002' },
    { name: 'geoICBM', content: '-36.8114642;174.6210002' },
  ];

  const images = useStaticQuery(
    graphql`
      query {
        laptopMS: file(
          relativePath: { eq: "quashed-plus/ms-laptop-mockup.png" }
        ) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mobileMS: file(
          relativePath: { eq: "quashed-plus/ms-phone-mockup.png" }
        ) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        jessProfile: file(relativePath: { eq: "quashed-plus/Jess.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        sheldonProfile: file(relativePath: { eq: "quashed-plus/Sheldon.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        johnProfile: file(relativePath: { eq: "quashed-plus/John.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        newZealand: file(relativePath: { eq: "new-zealand-white.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        priceIncreaseGraph: file(
          relativePath: { eq: "quashed-plus/priceIncreaseGraph.png" }
        ) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        noRisk: file(relativePath: { eq: "quashed-plus/noRisk.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        savings: file(relativePath: { eq: "quashed-plus/savings.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `,
  );
  return (
    <Layout
      footerClassName={styles.footer}
      transparent={true}
      navLinks={[]}
      hideSidebarLinks={true}
    >
      <SEO title="Quashed | All your insurance in one place"
          ogTitle="Quashed | All your insurance in one place"
          description="Compare and save on car, house, contents, and life insurance in minutes. Shop and track all your insurance in one place."
          meta={meta} />
      <PageHeader
        title="Kiwis save an average of $1,150 a year on insurance with Market Scan"
        subtitle="Car insurance, house insurance, contents insurance, life insurance, motorcycle, pet and more."
        image={
          <Image
            className={styles.headerImage}
            fluid={images.laptopMS.childImageSharp.fluid}
          />
        }
        className={styles.header}
        wrapClassName={styles.headerWrap}
        leftClassName={styles.leftHeader}
        rightClassName={styles.rightHeader}
        titleClassName={styles.headerTitle}
        subtitleClassName={styles.headerSubtitle}
        hideArrow={true}
        buttonText="Get started for free"
        buttonLink={GET_STARTED}
      />
      <Scroll>
        <SponsorSection />
      </Scroll>
      <Scroll>
        <Section1 newZealand={images.newZealand} />
      </Scroll>
      <Scroll>
        <Section2 images={images} />
      </Scroll>
      <Scroll>
        <Section3 images={images} />
      </Scroll>
      <Scroll>
        <Section4 images={images} />
      </Scroll>
      <Scroll>
        <Section5 />
      </Scroll>
      <Scroll>
        <Section6 images={images} />
      </Scroll>
    </Layout>
  );
};

export default Home;
